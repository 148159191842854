/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { InputType, ResourceType, SelectRenderType } from 'm6BoBuilder';
import { RessourceMode } from '../../m6BoBuilder/types/ResourceType';
import BaseSchema from 'yup/lib/schema';
import { GENDER_OPTIONS, ROLES_OPTIONS } from 'constants/common';
import UserListStatusCell from 'components/users/UserListStatusCell';
import UserListRoleCell from 'components/users/UserListRoleCell';
import UserListProfileCell from 'components/users/UserListProfileCell';
import UserListDatetimeCell from 'components/users/UserListDatetimeCell';
import UserListPaymentCell from 'components/users/UserListPaymentCell';
//import ListDatetimeCell from 'components/table/ListDatetimeCell';
import ListOfferDatetimeCell from 'components/users/ListOfferDatetimeCell';

const UserProperties = {
    id: 'id',
    fullname: 'fullname',
    gender: 'gender',
    firstname: 'firstname',
    lastname: 'lastname',
    email: 'email',
    birthdate: 'birthdate',
    updatedAt: 'updatedAt',
    roles: 'roles',
    address: 'address',
    height: 'height',
    weight: 'weight',
    stripeCustomerId: 'stripeCustomerId',
    automaticSubscription: 'automaticSubscription',
    lastLogin: 'lastLogin',
    goalWeight: 'goalWeight',
    password: 'plainPassword',
    confirmPassword: 'confirmPassword',
    paymentMethodOrigin: 'paymentMethodOrigin',
    promoCodes: 'promoCodes',
    lastOffer: 'lastOffer',
    lastOffers: 'lastOffers',
    avatar: 'avatar',
};

const paymentMethodValues = [
    { value: 'APPLE', label: 'Apple' },
    { value: 'GOOGLE', label: 'Google' },
    { value: 'STRIPE', label: 'Stripe' },
];

const User: ResourceType = {
    resourcePath: 'users',
    placeHolder: {
        [UserProperties.email]: '',
        [UserProperties.gender]: null,
        [UserProperties.firstname]: '',
        [UserProperties.lastname]: '',
        [UserProperties.roles]: [],
        [UserProperties.birthdate]: null,
        [UserProperties.goalWeight]: 0.0,
        [UserProperties.height]: null,
        [UserProperties.address]: null,
        [UserProperties.stripeCustomerId]: '',
        [UserProperties.password]: '',
        [UserProperties.avatar]: null,
    },
    validationSchema: (context: RessourceMode) => {
        const plainPasswordValidation: BaseSchema = yup
            .string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])(?=.{12,})/,
                'Votre mot de passe doit contenir au moins 12 caractères dont 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial.',
            )
            .nullable();

        if (context === RessourceMode.Create) {
            plainPasswordValidation.required('Ce champ est obligatoire');
        }

        const confirmPasswordValidation: BaseSchema = yup
            .string()
            .nullable()
            .when(UserProperties.password, {
                is: (value: any) => value && value.length > 0,
                then: yup
                    .string()
                    .required('Ce champ est obligatoire')
                    .oneOf([yup.ref(UserProperties.password)], 'Confirmation invalide'),
            });

        return yup.object({
            [UserProperties.email]: yup.string().email().required('Ce champ est obligatoire'),
            [UserProperties.password]: plainPasswordValidation,
            [UserProperties.confirmPassword]: confirmPasswordValidation,
        });
    },
    configForm: {
        label: 'Utilisateur',
        listInputs: [
            {
                label: 'Avatar',
                property: UserProperties.avatar,
                type: InputType.image,
                gridItemSize: 8,
                options: {
                    resource: 'avatars',
                },
            },
            {
                label: 'Civilité',
                property: UserProperties.gender,
                type: InputType.select,
                options: {
                    choices: GENDER_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Prénom',
                property: UserProperties.firstname,
            },
            {
                label: 'Nom',
                property: UserProperties.lastname,
            },
            {
                label: 'Email',
                property: UserProperties.email,
            },
            {
                label: 'Mot de passe',
                property: UserProperties.password,
                type: InputType.password,
            },
            {
                label: 'Confirmer le mot de passe',
                property: UserProperties.confirmPassword,
                type: InputType.password,
            },
            {
                label: 'Rôles',
                property: UserProperties.roles,
                type: InputType.select,
                options: {
                    choices: ROLES_OPTIONS,
                    render: SelectRenderType.select,
                    multiple: true,
                },
            },
            {
                label: 'Id Stripe',
                property: UserProperties.stripeCustomerId,
                type: InputType.text,
            },
            {
                label: "Complément d'addresse",
                property: `${UserProperties.address}.streetAddress`,
                type: InputType.text,
            },
            {
                label: 'Code postal',
                property: `${UserProperties.address}.postalCode`,
                type: InputType.text,
            },
            {
                label: 'Ville',
                property: `${UserProperties.address}.city`,
                type: InputType.text,
            },
            {
                label: 'Taille',
                property: UserProperties.height,
                type: InputType.integer,
            },
            {
                label: 'Date anniversaire',
                property: UserProperties.birthdate,
                type: InputType.date,
            },
            {
                label: 'Objectif de poids',
                property: UserProperties.goalWeight,
                type: InputType.float,
            },
            {
                label: 'Pays',
                property: `${UserProperties.address}.addressCountry`,
                type: InputType.text,
            },
        ],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeSubmit: (entity: Record<string, any>, _context: RessourceMode): Record<string, any> => {
            delete entity.confirmPassword;
            if (null === entity.plainPassword) {
                delete entity.plainPassword;
            }
            return entity;
        },
    },
    configList: {
        label: 'Utilisateur',
        resourcePath: 'bo-users',
        itemsPerPage: 50,
        defaultOrder: {
            property: 'id',
            order: 'desc',
        },
        listProperties: [
            {
                label: 'N°',
                property: UserProperties.id,
                sortable: true,
            },
            {
                label: 'Utilisateur',
                property: UserProperties.fullname,
                transform: UserListProfileCell,
            },
            /*{
                label: 'Prénom et nom',
                property: UserProperties.fullname,
                sortable: true,
            },
            {
                label: 'Email',
                property: UserProperties.email,
                sortable: true,
            },*/
            {
                label: 'Stripe ID',
                property: UserProperties.stripeCustomerId,
                sortable: true,
            },
            {
                label: 'Méthode de paiement',
                property: `${UserProperties.lastOffer}.paymentMethodOrigin`,
                sortable: true,
                transform: UserListPaymentCell,
            },
            {
                label: 'Rôles',
                property: UserProperties.roles,
                transform: UserListRoleCell,
                sortable: true,
            },
            {
                label: 'Statut',
                property: UserProperties.lastOffers,
                transform: UserListStatusCell,
            },
            {
                label: 'Date de souscription',
                property: UserProperties.lastOffers,
                transform: ListOfferDatetimeCell,
                //transform: ListDatetimeCell, //(date: string): string => DateConverter.display(date, true),
                //sortable: true,
            },
            {
                label: 'Dernière connexion',
                property: UserProperties.lastLogin,
                transform: UserListDatetimeCell, //(date: string): string => DateConverter.display(date, true),
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Email',
                property: UserProperties.email,
            },
            {
                label: 'Stripe ID',
                property: UserProperties.stripeCustomerId,
            },
            {
                label: 'Nom de famille',
                property: UserProperties.lastname,
            },
            {
                label: 'Code promos',
                property: 'offers.promoCode.code',
            },
            {
                label: 'Méthode de paiement',
                property: 'offers.paymentMethodOrigin',
                type: InputType.select,
                options: {
                    choices: paymentMethodValues,
                    render: SelectRenderType.select,
                    multiple: true,
                },
            },
            {
                label: 'Rôles',
                property: UserProperties.roles,
                type: InputType.select,
                options: {
                    choices: ROLES_OPTIONS,
                    render: SelectRenderType.select,
                    multiple: true,
                },
            },
        ],
    },
};

export default User;
export { UserProperties };
