import { ROLES } from './users';
import { StatusOptionType } from './type';

export enum SERVICE {
    CROQKILOS = 0,
    CROQBODY = 1,
    YOGICLUB = 2,
}

export const SERVICE_COLOR: Record<string, string> = {
    '0': '#F3DB23',
    '1': '#005384',
    '2': '#7359cd',
    CROQKILOS: '#F3DB23',
    CROQBODY: '#005384',
    YOGICLUB: '#7359cd',
};

export enum PAYMENT_PROVIDER {
    STRIPE = 'STRIPE',
    APPLE = 'APPLE',
    GOOGLE = 'GOOGLE',
    PAYBOX = 'PAYBOX',
    MANUAL = 'MANUAL',
}

export const PAYMENT_PROVIDER_COLOR: Record<string, string> = {
    STRIPE: '#7A73FF',
    APPLE: '#979797',
    GOOGLE: '#A4C639',
    PAYBOX: '#1F99CF',
    MANUAL: '#014890',
};

export const PAYMENT_PROVIDER_LOGO: Record<string, string> = {
    STRIPE: `/images/logos/stripe-logo.png`,
};

export const SERVICE_LABEL: Record<string, string> = {
    '0': `Croq'Kilos`,
    '1': `Croq'Body`,
    '2': `YogiClub`,
    CROQKILOS: `Croq'Kilos`,
    CROQBODY: `Croq'Body`,
    YOGICLUB: `YogiClub`,
};

export const SERVICE_LOGO: Record<string, string> = {
    CROQKILOS: `/images/croqkilos-logo.png`,
    CROQBODY: `/images/croqbody-logo.png`,
    YOGICLUB: `/images/yogi-club-logo.png`,
};

export const SERVICE_OPTIONS: StatusOptionType[] = [
    { value: SERVICE.CROQKILOS, label: SERVICE_LABEL.CROQKILOS },
    { value: SERVICE.CROQBODY, label: SERVICE_LABEL.CROQBODY },
    { value: SERVICE.YOGICLUB, label: SERVICE_LABEL.YOGICLUB },
];

export const ROLE_COLOR: Record<string, string> = {
    ROLE_SUPER_ADMIN: '#CE3535',
    ROLE_ADMIN: '#60a554',
    ROLE_CLIENT_CK: SERVICE_COLOR.CROQKILOS,
    ROLE_CLIENT_CB: SERVICE_COLOR.CROQBODY,
    ROLE_CLIENT_YG: SERVICE_COLOR.YOGICLUB,
    ROLE_GUEST: '#00c0ef',
};

export const ROLE_LABEL_COLOR: Record<string, string> = {
    'Super Administrateur': ROLE_COLOR.ROLE_SUPER_ADMIN,
    Administrateur: ROLE_COLOR.ROLE_ADMIN,
    Croqkilos: ROLE_COLOR.ROLE_CLIENT_CK,
    Croqbody: ROLE_COLOR.ROLE_CLIENT_CB,
    YogiClub: ROLE_COLOR.ROLE_CLIENT_YG,
    Influenceur: ROLE_COLOR.ROLE_GUEST,
};

export const ROLES_OPTIONS: StatusOptionType[] = [
    { value: ROLES.ROLE_USER, label: 'Utilisateur' },
    { value: ROLES.ROLE_CLIENT_CK, label: SERVICE_LABEL.CROQKILOS },
    { value: ROLES.ROLE_CLIENT_CB, label: SERVICE_LABEL.CROQBODY },
    { value: ROLES.ROLE_CLIENT_YG, label: SERVICE_LABEL.YOGICLUB },
    { value: ROLES.ROLE_GUEST, label: 'Influenceur' },
    { value: ROLES.ROLE_ADMIN, label: 'Administrateur' },
    { value: ROLES.ROLE_SUPER_ADMIN, label: 'Super Administrateur' },
];

export enum PUBLISHED_STATUS {
    DRAFT = 0,
    PLANNED = 1,
    PUBLISHED = 2,
}

export const PUBLISHED_STATUS_OPTIONS: StatusOptionType[] = [
    { value: PUBLISHED_STATUS.DRAFT, label: 'Brouillon' },
    { value: PUBLISHED_STATUS.PUBLISHED, label: 'Publié' },
];

export enum COMMENT_STATUS {
    NO_VALID = 0,
    VALID = 1,
    WAITING = 2,
}

export const COMMENT_STATUS_OPTIONS: StatusOptionType[] = [
    { value: COMMENT_STATUS.WAITING, label: 'En attente' },
    { value: COMMENT_STATUS.VALID, label: 'Validé' },
    { value: COMMENT_STATUS.NO_VALID, label: 'Non valide' },
];

export const GENDER_OPTIONS = [
    { value: '1', label: 'Madame' },
    { value: '0', label: 'Monsieur' },
];

export const REDUCTION_TYPE = [
    { value: 1, label: 'Euro' },
    { value: 0, label: '%' },
];

export const YES_OR_NO_OPTIONS = [
    { value: false, label: 'Non' },
    { value: true, label: 'Oui' },
];
